import React from 'react'
import Layout from 'layouts/AuthenticatedPage'
import SEO from 'components/SEO/SEO'
import { Link } from 'gatsby'

import styles from './submit.module.scss'

const SuccessfulRentalPage = props => {
  return (
    <div className={styles.container}>
      <article>
        <h1>You're All Done!</h1>
        <p>
          You should be receiving a confirmation email soon. If you do not
          receive a confirmation email or do no not want to receive confirmation
          emails, then make sure to{' '}
          <Link target="blank" to={'/dashboard/settings'}>
            review your notification settings
          </Link>
          .
        </p>
        <h3>What's Next</h3>
        <p>
          We'll let you know when the rental has been approved by the lender.
          From there you and the lender can message each other and figure out
          further details if necessary.
        </p>
        <p>Here are some helpful reminders:</p>
        <ul>
          <li>
            <p>You will only be charged if the lender accepts the rental.</p>
          </li>
          <li>
            <p>
              If the lender does not respond within an appropriate amount of the
              time then the rental will be canceled automatically and you will
              not be charged.
            </p>
          </li>
          <li>
            <p>
              If you have any questions, feedback, or concerns then be sure to{' '}
              <a href="mailto:team@ridemellow.com">contact us</a>.
            </p>
          </li>
        </ul>
        <p>
          You can awalys review the cancellation policy, read on how to resolve
          disputes, and much more at the{' '}
          <a href="https://help.borrowmellow.com/">Help Center</a>
        </p>
        <h3>
          <Link to={'/search'}>Go Back To Search</Link>
        </h3>
      </article>
    </div>
  )
}

export default ({ location }) => (
  <Layout location={location} displayFooter={false}>
    <SEO title="Rental" />
    <SuccessfulRentalPage location={location} />
  </Layout>
)
